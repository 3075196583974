
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  watch,
  onUnmounted,
  getCurrentInstance,
} from "vue";
import { isPlatform } from "@ionic/vue";
import { useRouter, useRoute } from "vue-router";
import Utils from "@/utils/Utils";
import { AnyARecord } from "dns";
import "./WavAudioEncoder.min";
import { AiuiService } from "@/services/aiuiservice";
import { getMicrophonePermission, isMobile } from "./AudioUtils";
import Header from "@/components/Header.vue";
import TaskCards from "@/components/ListCard/TaskCards.vue";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import Setting from "@/rest/Setting";
import { nextTick, title } from "process";
import SystemUtils from "@/utils/SystemUtils";
import { TaskService } from "@/services/task/TaskService";
import { getInstance } from "@/services/selectexecutor/UserInfoByUserNameService";
import moment from "moment";
import { TextToSpeech } from "@ionic-native/text-to-speech";
import { EUserWorkType } from "@/models/task/Enum";
import { TaskUserService } from "@/services/task/TaskUserService";
import { ScheduleService } from "@/services/schedule/ScheduleService";
import OtherScheduleService from "@/services/calendar/ScheduleService";
import { ProjectService } from "@/services/project/projectService";
import { ObjectiveService } from "@/services/okr/ObjectiveService";
import ScheduleCard from "@/components/ListCard/ScheduleCard.vue";
import TargetCards from "@/components/ListCard/TargetCards.vue";
import ProjectCss from "@/components/ListCard/ProjectCss.vue";
import { File } from "@ionic-native/file";
import { json } from "gantt";
import { FileHelper } from "@/utils/FileHelper";
import { CalendarNative, EventOption } from "@/utils/CalendarNative";
import Bus from "@/utils/bus";
export default defineComponent({
  name: "AiuiSearchCondition_copy",
  components: {
    Header,
    TaskCards,
    CardLoading,
    ScheduleCard,
    ProjectCss,
    TargetCards,
  },
  setup(props, context) {
    const route = useRoute(); // 返回当前路由位置。相当于在内部使用“$route” 获取路由传参
    const router = useRouter(); //返回路由器实例。相当于在内部使用“$router” 进行路由跳转
    const taskUserApi = new TaskUserService();
    const scheduleService = new ScheduleService();
    const OtherscheduleService = new OtherScheduleService();
    const projectService = new ProjectService();
    const objectiveService = new ObjectiveService();
    const calendarNative = new CalendarNative();
    //iconyuyinbobaoguanbi
    let btnIconRight: any = reactive({ data: ["iconyuyinbobao"] });
    // const btnSizeRight: any = reactive({ data: ["34px"] });
    let speakDisabled = ref(false);
    let selectUserId = ref("");
    let flagCount = ref(0);
    let maxTime = 10;
    let taskService = new TaskService();
    let userService = getInstance();
    let fileHelper = new FileHelper();
    let clickType = "";
    let clickIndex = -1;

    let isSVW = Setting.isSVW; //是否上汽环境，隐藏部分功能
    let voiceText = ref("按住说话");
    let currentUserName = ref("");
    let islist = ref(true);
    if (SystemUtils.loginUser.name)
      currentUserName.value = SystemUtils.loginUser.name;

    const searchQuestion = reactive({
      data: [
        {
          id: "1",
          name: "查询我今天的待办任务",
          json:
            '{"data":[{"sub":"iat","auth_id":"1f813a386ccc4f0c9f1f3cc79b6ea14c","text":"查询我今天的待办任务","result_id":1,"json_args":{"language":"zh-cn","accent":"mandarin"}},{"sub":"nlp","auth_id":"1f813a386ccc4f0c9f1f3cc79b6ea14c","intent":{"answer":{"text":"查询到{count}个任务","type":"T"},"category":"OS7105915014.ATT_PersonLeave","data":{"result":[{"DomainClassinfo":"Task","UserName":"My","answerText":"查询到{count}个任务","answerTextForNot":"未查询到相关任务","creatorName":null,"date":"Y2021M05D11","endTime":null,"getQueryItem":null,"intentName":"Query_Task_Info","overdueStatus":null,"priority":null,"speak":"end","suggestDatetime":"2021-05-11","taskIsFinished":"0","taskName":null}]},"intentType":"custom","rc":0,"semantic":[{"entrypoint":"ent","hazard":false,"intent":"Query_Task_Info","score":1,"slots":[{"begin":2,"end":3,"name":"UserName","normValue":"My","value":"我"},{"begin":3,"end":5,"name":"date","normValue":"{\\"datetime\\":\\"Y2021M05D11\\",\\"suggestDatetime\\":\\"2021-05-11\\"}","value":"今天"},{"begin":5,"end":6,"name":"yq","normValue":"的","value":"的"},{"begin":6,"end":8,"name":"taskIsFinished","normValue":"code_0","value":"待办"}],"template":"查询{UserName}{date}{yq}{taskIsFinished}任务"}],"semanticType":0,"service":"OS7105915014.ATT_PersonLeave","sessionIsEnd":true,"shouldEndSession":true,"sid":"ara20ebd086@dx000113f579a1a15800","state":null,"text":"查询我今天的待办任务","uuid":"ara20ebd086@dx000113f579a1a15800","vendor":"OS7105915014","version":"63.0","voice_answer":[{"content":"查询到{count}个任务","type":"TTS"}]},"result_id":1}],"sid":"ara20ebd086@dx000113f579a1a15800","code":"0","desc":"success"}',
        },
        {
          id: "2",
          name: "查询" + SystemUtils.loginUser.name + "本周的任务",
          json:
            '{"data":[{"sub":"iat","auth_id":"1f813a386ccc4f0c9f1f3cc79b6ea14c","text":"查询' +
            SystemUtils.loginUser.name +
            '本周的任务","result_id":1,"json_args":{"language":"zh-cn","accent":"mandarin"}},{"sub":"nlp","auth_id":"1f813a386ccc4f0c9f1f3cc79b6ea14c","intent":{"answer":{"text":"查询到{count}个任务","type":"T"},"category":"OS7105915014.ATT_PersonLeave","data":{"result":[{"DomainClassinfo":"Task","UserName":"My","answerText":"查询到{count}个任务","answerTextForNot":"未查询到相关任务","creatorName":null,"date":"Y2021W20","endTime":null,"getQueryItem":null,"intentName":"Query_Task_Info","overdueStatus":null,"priority":null,"speak":"end","suggestDatetime":"2021-05-11","taskIsFinished":null,"taskName":null}]},"intentType":"custom","rc":0,"semantic":[{"entrypoint":"ent","hazard":false,"intent":"Query_Task_Info","score":1,"slots":[{"begin":2,"end":3,"name":"UserName","normValue":"My","value":"我"},{"begin":3,"end":5,"name":"date","normValue":"{\\"datetime\\":\\"Y2021W20\\",\\"suggestDatetime\\":\\"2021-05-11\\"}","value":"本周"},{"begin":5,"end":6,"name":"yq","normValue":"的","value":"的"}],"template":"查询{UserName}{date}{yq}任务"}],"semanticType":0,"service":"OS7105915014.ATT_PersonLeave","sessionIsEnd":true,"shouldEndSession":true,"sid":"ara20eafca1@dx000113f578a5a10b00","state":null,"text":"查询' +
            SystemUtils.loginUser.name +
            '本周的任务","uuid":"ara20eafca1@dx000113f578a5a10b00","vendor":"OS7105915014","version":"63.0","voice_answer":[{"content":"查询到{count}个任务","type":"TTS"}]},"result_id":1}],"sid":"ara20eafca1@dx000113f578a5a10b00","code":"0","desc":"success"}',
        },
        {
          id: "3",
          name: "查询我延期完成的任务",
          json:
            '{"data":[{"sub":"iat","auth_id":"1f813a386ccc4f0c9f1f3cc79b6ea14c","text":"查询我延期完成的任务","result_id":1,"json_args":{"language":"zh-cn","accent":"mandarin"}},{"sub":"nlp","auth_id":"1f813a386ccc4f0c9f1f3cc79b6ea14c","intent":{"answer":{"text":"查询到{count}个任务","type":"T"},"category":"OS7105915014.ATT_PersonLeave","data":{"result":[{"DomainClassinfo":"Task","UserName":"My","answerText":"查询到{count}个任务","answerTextForNot":"未查询到相关任务","creatorName":null,"date":null,"endTime":null,"getQueryItem":null,"intentName":"Query_Task_Info","overdueStatus":"2","priority":null,"speak":"end","suggestDatetime":"2021-05-11","taskIsFinished":"1","taskName":null}]},"intentType":"custom","rc":0,"semantic":[{"entrypoint":"ent","hazard":false,"intent":"Query_Task_Info","score":1,"slots":[{"begin":2,"end":3,"name":"UserName","normValue":"My","value":"我"},{"begin":3,"end":5,"name":"overdueStatus","normValue":"code_2","value":"延期"},{"begin":5,"end":8,"name":"taskIsFinished","normValue":"code_1","value":"完成的"}],"template":"查询{UserName}{overdueStatus}{taskIsFinished}任务"}],"semanticType":0,"service":"OS7105915014.ATT_PersonLeave","sessionIsEnd":true,"shouldEndSession":true,"sid":"ara0c842da2@dx000113f57a2da14200","state":null,"text":"查询我延期完成的任务","uuid":"ara0c842da2@dx000113f57a2da14200","vendor":"OS7105915014","version":"63.0","voice_answer":[{"content":"查询到{count}个任务","type":"TTS"}]},"result_id":1}],"sid":"ara0c842da2@dx000113f57a2da14200","code":"0","desc":"success"}',
        },
      ],
    });

    const searchQuestionClick = (item: any) => {
      stopCapture(false, item.json as string);
    };

    const fileSystemPath = isPlatform("ios")
      ? File.dataDirectory
      : File.externalRootDirectory;

    const taskList = reactive({ data: new Array<any>() });
    const scheduleList = reactive({ data: new Array<any>() });
    const projectLists = reactive({ data: new Array<any>() });
    const targetList = reactive({ data: new Array<any>() });
    let isclick = true;
    let startY: any = null;
    let startX: any = 0;
    let moveY: any = ref(0);
    let voiceStatus: any = ref(0);
    let animationIndex: any = ref(0);
    let title = ref("语音交互");

    let captureCfg: any = null; // Capture configuration object
    let audioDataBuffer: any = new Array(); // Audio Buffer
    let objectURL: any = null; // For file URL; // Info/Debug
    // URL shim
    window.URL = window.URL || window.webkitURL;

    let repContent = ref(new Array());
    let fileUrl = "";
    let fileName = "";

    let chatArr = reactive({ data: new Array<any>() });

    const pushChatArr = (
      isWaited: boolean,
      question: any,
      answer: any,
      audioTime?: any
    ) => {
      let model = {
        isWaited: isWaited,
        question: question,
        answer: answer,
        userList: new Array<any>(),
        audioTime: audioTime,
      };
      console.log("model: " + JSON.stringify(model));
      if (audioTime && audioTime == "none") {
        chatArr.data.splice(chatArr.data.length - 1, 1, model);
      } else {
        chatArr.data.push(model);
        textToSpeech(answer);
      }
      //最新消息滚动到顶部
      scrollToTop();
    };
    const aiuiService = new AiuiService();
    // const { App } = Plugins;
    onMounted(() => {
      Bus.$emit("start_or_stop_audio_listener", false);
      Bus.$emit("show_app_button", false);
      islist.value = false;
      if ("taskList" in route.params) {
        let taskParams = route.params.taskList + "";
        taskList.data = JSON.parse(taskParams);
        title.value = "新建结果";
      }

      if ("scheduleList" in route.params) {
        scheduleList.data = JSON.parse(route.params.scheduleList as string);
        title.value = "新建结果";
      }

      if ("aiuiJSON" in route.params) {
        let aiuiJson = route.params.aiuiJSON;
        stopCapture(false, aiuiJson as string);
      }

      // Make it possible to run the demo on desktop.
      if (!(<any>window).cordova) {
        console.log("Running on desktop!");
        onDeviceReady();
      } else {
        // For Cordova apps
        console.log("Running on device!");
        document.addEventListener("deviceready", onDeviceReady, false);
        // islist.value = false;
      }

      // 更新数据
      Bus.$on("data_update_listener", (data: any) => {
        if (clickIndex < 0) return;
        switch (data.type) {
          case "task":
            if (taskList.data && taskList.data.length > 0) {
              getTaskInfo(taskList.data[clickIndex].id);
            }
            break;
          case "schedule":
            if (scheduleList.data && scheduleList.data.length > 0) {
              getScheduleInfo(data.data.id, data.data.scheduleTimeId);
            }
            break;
          case "project":
            if (
              projectLists.data &&
              projectLists.data.length > 0 &&
              data.data
            ) {
              projectLists.data[clickIndex] = data.data;
            }
            break;
          case "target":
            if (targetList.data && targetList.data.length > 0) {
              getTargetInfo(targetList.data[clickIndex].id);
            }
            break;
        }
      });
    });

    onUnmounted(() => {
      (<any>window).removeEventListener(
        "audioinput",
        onAudioInputCapture,
        false
      );
      (<any>window).removeEventListener(
        "audioinputerror",
        onAudioInputError,
        false
      );
      Bus.$emit("start_or_stop_audio_listener", true);
      Bus.$emit("show_app_button", true);
      Bus.$off("data_update_listener");
    });
    // 开始录制
    const touchstart = (e: any) => {
      moveY = 1;
      voiceStatus.value = 1;
      e.preventDefault();
      getInterval();
      voiceText.value = "松开识别";
      startY = e.changedTouches[0].pageY;
      startX = e.changedTouches[0].pageX;
      isclick = true;
      startCapture();
    };
    // 音阶动画
    const animation = () => {
      let num = Math.ceil(Math.random() * 5);
      if (animationIndex.value > 1) {
        animationIndex.value = 1;
      }
      if (animationIndex.value % 2 == 0 && animationIndex.value > 0) {
        animationIndex.value -= num;
      } else {
        animationIndex.value += num;
      }
    };
    let animationInterval: any = null;

    const getInterval = () => {
      if (animationInterval == null) {
        animationInterval = setInterval(animation, 300);
      }
    };
    const textToSpeech = (answer: any) => {
      if (btnIconRight.data[0] == "iconyuyinbobao") {
        Utils.textTospeech(answer);
      }
    };

    // 保存日程信息到手机本地日历
    const saveCalendarEvent = (schedule: any) => {
      let title = schedule.name;
      let location = schedule.address;
      let notes = schedule.remark;
      let partUsers = schedule.scheduleParticipantList;
      let startDate = new Date(schedule.startTime.replace(/\-/g, "/"));
      let endDate = new Date(schedule.endTime.replace(/\-/g, "/"));
      calendarNative.findAllCalendar().then(
        (r: any) => {
          if (r && r.length > 0) {
            let noteUsers = "";
            if (partUsers && partUsers.length > 0) {
              noteUsers = "参与人：";
              partUsers.forEach((user: any) => {
                noteUsers += user.userName + "、";
              });
              noteUsers = noteUsers.substring(0, noteUsers.length - 1);
            }
            if (startDate.getTime() == endDate.getTime()) {
              endDate.setTime(endDate.getTime() + 1000 * 60);
            }
            let option: EventOption = {
              calendarName: "",
              calendarId: "",
              title: title,
              eventLocation: location,
              notes: noteUsers + (notes != "" ? "\n说明：" + notes : ""),
              startDate: startDate,
              endDate: endDate,
            };
            if (isPlatform("ios")) {
              option.calendarName = r[0].name;
            } else {
              option.calendarId = r[0].id;
            }
            //创建
            calendarNative.createEventSilently(option).then(
              (r: any) => {
                updateMobileScheduleId(schedule, r);
                console.log("日程标识：" + JSON.stringify(r));
              },
              (e: boolean) => {
                console.log("异常：" + JSON.stringify(e));
              }
            );
          } else {
            console.log("没有日历");
          }
        },
        (e: any) => {
          console.log("获取日历失败");
        }
      );
    };

    // 更新手机日历事件标识
    const updateMobileScheduleId = (schedule: any, eventId: string) => {
      let eventJson =
        schedule.mobileScheduleId == undefined ||
        schedule.mobileScheduleId == ""
          ? { ios: "", android: "" }
          : JSON.parse(schedule.mobileScheduleId);
      if (isPlatform("ios")) {
        eventJson.ios = eventId;
      } else {
        eventJson.android = eventId;
      }
      const params = {
        id: schedule.id,
        setFields: ["mobileScheduleId"],
        mobileScheduleId: JSON.stringify(eventJson),
      };
      scheduleService.updateFieldsById(params).then((res: any) => {
        console.log("修改日程事件成功");
      });
    };

    const changeIon = () => {
      if (btnIconRight.data[0] == "iconyuyinbobao") {
        textToSpeech("");
        btnIconRight.data = ["iconyuyinbobaoguanbi"];
      } else {
        btnIconRight.data = ["iconyuyinbobao"];
      }
    };

    const changePlay = (isPlay: boolean) => {
      if (isPlay) {
        btnIconRight.data = ["iconyuyinbobao"];
      } else {
        textToSpeech("");
        btnIconRight.data = ["iconyuyinbobaoguanbi"];
      }
    };
    // 结束说话 停止录制
    const touchend = () => {
      if (flagCount.value > maxTime) {
        return;
      }
      console.log("结束说话");
      // moveY = 0;
      if (animationInterval != null) {
        clearInterval(animationInterval);
        animationInterval = null;
      }
      voiceText.value = "按住说话";
      if (moveY < -30) {
        isclick = true;
      } else {
        isclick = false;
      }
      if (voiceStatus.value == 1) speakDisabled.value = true; // 禁用讲话按钮
      voiceStatus.value = 0;
      setTimeout(() => {
        stopCapture(isclick);
      }, 1500);
    };
    const touchmove = (e: any) => {
      e.preventDefault();
      var moveEndY = e.changedTouches[0].pageY;
      moveY = moveEndY - startY;
      if (flagCount.value <= maxTime) {
        if (moveY < -30) {
          voiceStatus.value = 2;
          voiceText.value = "松开取消";
          isclick = true;
        } else {
          voiceStatus.value = 1;
          voiceText.value = "松开识别";
          isclick = false;
        }
      }
    };

    /**
     * Called continuously while AudioInput capture is running.
     */
    const onAudioInputCapture = (evt: any) => {
      try {
        if (evt && evt.data) {
          audioDataBuffer.push(...evt.data);
          if (flagCount.value >= maxTime) {
            //超过10s自动停止
            touchend();
          }
          flagCount.value++;
        }
      } catch (ex) {
        alert("onAudioInputCapture ex: " + ex);
      }
    };

    /**
     * Called when a plugin error happens.
     */
    const onAudioInputError = (error: any) => {
      alert("onAudioInputError event recieved: " + JSON.stringify(error));
    };

    /**
     * When cordova fires the deviceready event, we initialize everything needed for audio input.
     */
    const onDeviceReady = () => {
      if (
        (<any>window).cordova &&
        (<any>window).cordova.file &&
        (<any>window).audioinput
      ) {
        console.log("Use 'Start Capture' to begin...");
        // Subscribe to audioinput events
        (<any>window).addEventListener(
          "audioinput",
          onAudioInputCapture,
          false
        );
        (<any>window).addEventListener(
          "audioinputerror",
          onAudioInputError,
          false
        );

        // See utils.js
        getMicrophonePermission(
          () => {
            console.log("Microphone input starting...");
            console.log("Microphone input started!");
          },
          (deniedMsg: AnyARecord) => {
            console.log(deniedMsg);
          },
          (errorMsg: any) => {
            console.log(errorMsg);
          }
        );
      } else {
        console.log(
          "Missing: cordova-plugin-file or cordova-plugin-audioinput!"
        );
      }
    };

    /**
     * Start capturing audio.
     */
    const startCapture = () => {
      audioDataBuffer = new Array();
      flagCount.value = 0;
      objectURL = null;
      try {
        TextToSpeech.speak("");
        // let audioinput = (<any>window).audioinput;
        console.log("Aiui.vue-----startCapture");
        if (
          (<any>window).audioinput &&
          !(<any>window).audioinput.isCapturing()
        ) {
          captureCfg = {
            audioSourceType: 0,
            sampleRate: 16000,
            channels: 1,
            format: (<any>window).audioinput.FORMAT.PCM_16BIT,
          };

          // See utils.js
          getMicrophonePermission(
            () => {
              console.log("Microphone input starting...");
              (<any>window).audioinput.start(captureCfg);
              console.log("Microphone input started!");
              // Throw previously created audio
              if (objectURL) {
                URL.revokeObjectURL(objectURL);
              }
            },
            (deniedMsg: AnyARecord) => {
              console.log(deniedMsg);
            },
            (errorMsg: any) => {
              console.log(errorMsg);
            }
          );
        }
      } catch (e) {
        alert("startCapture exception: " + e);
      }
    };

    /**
     * Stop the capture, encode the captured audio to WAV, save it to a file and show file URL in UI.
     */
    const stopCapture = (isBlock: boolean, aiuiJSON?: string) => {
      try {
        if (aiuiJSON) {
          repContent.value = JSON.parse(aiuiJSON).data;
          console.log(
            "analysisAndOperation" + JSON.stringify(repContent.value)
          );
          analysisAndOperation(repContent.value);
          return;
        }
        if (isBlock == undefined) isBlock = false;
        if (
          (<any>window).audioinput &&
          (<any>window).audioinput.isCapturing()
        ) {
          if (isMobile.any() && (<any>window).audioinput) {
            setTimeout(() => {
              //延时结束录音
              (<any>window).audioinput.stop();
            }, 500);
            // (<any>window).audioinput.stop();
          }
          //取消搜索，终止执行
          if (isBlock) {
            return;
          }
          console.log("Encoding WAV...");
          let encoder = new (<any>window).WavAudioEncoder(
            (<any>window).audioinput.getCfg().sampleRate,
            (<any>window).audioinput.getCfg().channels
          );
          encoder.encode([audioDataBuffer]);

          console.log("Encoding WAV finished");

          let blob = encoder.finish("audio/wav");
          console.log("BLOB created");
          (<any>window).resolveLocalFileSystemURL(
            fileSystemPath,
            function (dir: any) {
              fileName = new Date().getTime() + ".wav";
              dir.getFile(fileName, { create: true }, function (file: any) {
                file.createWriter(
                  function (fileWriter: any) {
                    fileWriter.write(blob);
                    fileUrl = file.toURL();
                    pushChatArr(false, fileUrl, "", flagCount.value + "s ");
                    setTimeout(() => {
                      console.log("AIUI send audio file: " + fileUrl);
                      aiuiService
                        .aiuiSend({
                          filePath: fileUrl,
                          fileName: fileName,
                        })
                        .then((rep: any) => {
                          console.log(
                            "AIUI result json: " + JSON.stringify(rep.data)
                          );

                          repContent.value = JSON.parse(rep.data).data;
                          analysisAndOperation(repContent.value);
                        })
                        .catch((err) => {
                          if (typeof err === "string")
                            Utils.presentToastWarning(err);
                          else
                            Utils.presentToastWarning(
                              "上传失败，请检查网络设置"
                            );
                          console.log(err);
                        })
                        .finally(() => {
                          fileHelper.deleteNativeFile(
                            fileUrl.replace(fileName, ""),
                            fileName
                          );
                          speakDisabled.value = false; // 启用讲话按钮
                        });
                    }, 1000);
                  },
                  function () {
                    alert("FileWriter error!");
                  }
                );
              });
            }
          );
        } else {
          console.log("web");
        }
      } catch (e) {
        alert("stopCapture exception: " + e);
      }
    };

    // 格式化时间
    const formatDatetime = (
      date: any,
      suggestDatetime: any,
      isMerge: boolean = false
    ) => {
      let dateArr = [];
      if (date.indexOf("/") >= 0) {
        dateArr = date.split("/");
      } else {
        dateArr.unshift(date, date);
      }

      let suggestArr = [];
      if (suggestDatetime.indexOf("/") >= 0) {
        suggestArr = suggestDatetime.split("/");
      } else {
        suggestArr.unshift(suggestDatetime, suggestDatetime);
      }

      let start = "";
      let end = "";
      for (let i = 0; i < dateArr.length; i++) {
        let d = dateArr[i];
        let year = "";
        let month = "";
        let day = "";
        let T = "";
        let h = "";
        let m = "";
        let s = "";
        // 获取年
        let index = d.indexOf("Y");
        if (index >= 0) {
          year = d.substr(index + 1, 4);
        } else {
          year = moment().year().toString();
        }
        // 获取月
        index = d.indexOf("M");
        if (index >= 0) {
          month = d.substr(index + 1, 2);
        } else {
          month = (moment().month() + 1).toString();
          month = month.length < 2 ? "0" + month : month;
        }
        // 获取日
        index = d.indexOf("D");
        if (index >= 0) {
          day = d.substr(index + 1, 2);
        } else {
          if (d.indexOf("Y") < 0 && d.indexOf("M") < 0) {
            day = moment().date().toString();
            day = day.length < 2 ? "0" + day : day;
          } else {
            if (i == 0) {
              day = "01";
            } else {
              day = moment(year + "-" + month + "-01")
                .endOf("month")
                .format("YYYY-MM-DD");
              day = day.substr(day.length - 2);
            }
          }
        }
        // 周
        debugger;
        index = d.indexOf("W");
        if (index >= 0) {
          index = d.indexOf("WD");
          if (index >= 0) {
            //   console.log("2021-04-28T18:00:00".replace("T", " "));
            if (suggestArr[i].length > 10) {
              suggestArr[i] = suggestArr[i].replace("T", " ");
            }
            let wIndex = d.indexOf("W");
            let wdIndex = d.indexOf("WD");
            if (i == 0) {
              start =
                weekGetDate(
                  year,
                  d.substr(wIndex + 1, 2),
                  d.substr(wdIndex + 2, 2)
                ) + " ";
            } else {
              end =
                weekGetDate(
                  year,
                  d.substr(wIndex + 1, 2),
                  d.substr(wdIndex + 2, 2)
                ) + " ";
            }
          } else {
            if (i == 0) {
              start = moment(suggestArr[i])
                .startOf("isoWeek")
                .format("YYYY-MM-DD ");
            } else {
              end = moment(suggestArr[i])
                .endOf("isoWeek")
                .format("YYYY-MM-DD ");
            }
          }
        }
        // 月底
        index = d.indexOf("D00") >= 0 ? d.indexOf("D00") : d.indexOf("DEND");
        if (index >= 0) {
          let suggest =
            suggestArr[i].indexOf("-00") >= 0
              ? suggestArr[i].replace("-00", "-01")
              : suggestArr[i];
          if (i == 0)
            start = moment(suggest).endOf("month").format("YYYY-MM-DD ");
          else end = moment(suggest).endOf("month").format("YYYY-MM-DD ");
        }
        // 季度
        index = d.indexOf("Q");
        if (index >= 0) {
          if (i == 0) {
            start = moment(suggestArr[i])
              .startOf("quarter")
              .format("YYYY-MM-DD ");
          } else {
            end = moment(suggestArr[i]).endOf("quarter").format("YYYY-MM-DD ");
          }
        }
        // 日期时间前后
        index = d.indexOf("O");
        if (index >= 0) {
          index = d.indexOf("Y]");

          if (index >= 0) {
            if (i == 0) {
              start = suggestArr[i].substr(0, 4) + "-01-01 ";
              end = suggestArr[i].substr(0, 4) + "-12-31 ";
            }
          } else {
            let now = moment().format("YYYY-MM-DD HH:mm:ss");
            if (i == 0) {
              if (d.indexOf("-") >= 0) {
                end = now;
                start = suggestArr[i] + " ";
              } else {
                start = now;
                end = suggestArr[i] + " ";
              }
            }
          }
        }
        // 时间
        index = d.indexOf("T");
        if (index >= 0) {
          index = d.indexOf("h");
          if (index >= 0) {
            h = d.substr(index + 1, 2);
          }
          index = d.indexOf("m");
          if (index >= 0) {
            m = d.substr(index + 1, 2);
          } else {
            if (i == 0) {
              m = "00";
            } else {
              m = "59";
            }
          }
          index = d.indexOf("s");
          if (index >= 0) {
            s = d.substr(index + 1, 2);
          } else {
            if (i == 0) {
              s = "00";
            } else {
              s = "59";
            }
          }
          T = h + ":" + m + ":" + s;
          index = d.indexOf("T");
          let timeFrame = d.substr(index + 1);
          switch (timeFrame) {
            case "EAM":
              if (i == 0) {
                T = "01:00:00";
              } else {
                T = "06:00:00";
              }
              break;
            case "AM":
              if (i == 0) {
                T = "06:00:00";
              } else {
                T = "12:00:00";
              }
              break;
            case "MID":
              if (i == 0) {
                T = "12:00:00";
              } else {
                T = "13:00:00";
              }
              break;
            case "PM":
              if (i == 0) {
                T = "13:00:00";
              } else {
                T = "18:00:00";
              }
              break;
            case "EV":
              if (i == 0) {
                T = "18:00:00";
              } else {
                T = "20:00:0";
              }
              break;
            case "NI":
              if (i == 0) {
                T = "20:00:0";
              } else {
                T = "23:59:59";
              }
              break;
          }
        } else {
          if (i == 0) {
            T = "00:00:00";
          } else {
            T = "23:59:59";
          }
        }
        // 最后处理时间
        if (i == 0 && !start) {
          start = year + "-" + month + "-" + day + " ";
        }
        if (i == 0 && start && start.length < 19) {
          start = start + T;
        }
        if (i == 1 && !end && d.indexOf("O") <= 0) {
          end = year + "-" + month + "-" + day + " ";
        }
        if (i == 1 && end && end.length < 19) {
          end = end + T;
        }
      }
      console.log("formatDatetime： " + start + " 至 " + end);
      if (
        isMerge &&
        start.length >= 10 &&
        end.length >= 10 &&
        start.substr(0, 10) == end.substr(0, 10)
      ) {
        return start.substr(0, 10);
      }
      if (start.length < 19 || end.length < 19) {
        return null;
      }
      return [start, end];
    };

    // 根据第几周和周几获取时间
    const weekGetDate = (year: any, weeks: any, day: any) => {
      let dateStr = year + "-01-01";
      let date = new Date(dateStr.replace(/\-/g, "/"));
      // 获取当前星期几,0:星期一
      let time = date.getTime();
      //当这一年的1月1日为周日时则本年有54周,否则没有54周,没有则去除第54周的提示
      let _week = date.getDay();
      // 周几
      let _day = Number.parseInt(day);

      if (_week != 0) {
        //一年53周情况
        if (weeks == 54) {
          return null;
        }
        let cnt = 0; // 获取距离周末的天数
        if (_week == 0) {
          cnt = 7;
        } else if (_week == 1) {
          cnt = 6;
        } else if (_week == 2) {
          cnt = 5;
        } else if (_week == 3) {
          cnt = 4;
        } else if (_week == 4) {
          cnt = 3;
        } else if (_week == 5) {
          cnt = 2;
        } else if (_week == 6) {
          cnt = 1;
        }
        cnt += 1; //加1表示以星期一为一周的第一天    // 将这个长整形时间加上第N周的时间偏移
        time += cnt * 24 * 3600000; //第2周开始时间
        dateStr = parseInt(year, 10) + 1 + "-01-01";
        let nextYear = new Date(dateStr.replace(/\-/g, "/"));
        let nextWeek = nextYear.getDay();
        let lastcnt = 0; //获取最后一周开始时间到周末的天数
        if (nextWeek == 0) {
          lastcnt = 6;
        } else if (nextWeek == 1) {
          lastcnt = 0;
        } else if (nextWeek == 2) {
          lastcnt = 1;
        } else if (nextWeek == 3) {
          lastcnt = 2;
        } else if (nextWeek == 4) {
          lastcnt = 3;
        } else if (nextWeek == 5) {
          lastcnt = 4;
        } else if (nextWeek == 6) {
          lastcnt = 5;
        }
        if (weeks == 1) {
          //第1周特殊处理
          date.setTime(time - (7 - _day + 1) * 24 * 3600000);
          let end = moment(date).format("yyyy-MM-DD");
          return end;
        } else if (weeks == 53) {
          //第53周特殊处理
          let start = time + (weeks - 2) * 7 * 24 * 3600000;
          start = start + (_day - 1) * 24 * 3600000;
          date.setTime(start);
          let _date = moment(date).format("yyyy-MM-DD");
          return _date;
        } else {
          let start = time + (weeks - 2) * 7 * 24 * 3600000; //第n周开始时间
          start = start + (_day - 1) * 24 * 3600000;
          date.setTime(start);
          let _date = moment(date).format("yyyy-MM-DD");
          return _date;
        }
      } else {
        //一年54周情况
        let cnt = 0; // 获取距离周末的天数
        if (_week == 0 && weeks == 1) {
          //第一周
          cnt = 0;
        } else if (_week == 0) {
          cnt = 7;
        } else if (_week == 1) {
          cnt = 6;
        } else if (_week == 2) {
          cnt = 5;
        } else if (_week == 3) {
          cnt = 4;
        } else if (_week == 4) {
          cnt = 3;
        } else if (_week == 5) {
          cnt = 2;
        } else if (_week == 6) {
          cnt = 1;
        }
        cnt += 1; //加1表示以星期一为一周的第一天
        time += 24 * 3600000; //第2周开始时间
        dateStr = parseInt(year, 10) + 1 + "-01-01";
        let nextYear = new Date(dateStr.replace(/\-/g, "/"));
        let nextWeek = nextYear.getDay();
        let lastcnt = 0; //获取最后一周开始时间到周末的天数
        if (nextWeek == 0) {
          lastcnt = 6;
        } else if (nextWeek == 1) {
          lastcnt = 0;
        } else if (nextWeek == 2) {
          lastcnt = 1;
        } else if (nextWeek == 3) {
          lastcnt = 2;
        } else if (nextWeek == 4) {
          lastcnt = 3;
        } else if (nextWeek == 5) {
          lastcnt = 4;
        } else if (nextWeek == 6) {
          lastcnt = 5;
        }
        if (weeks == 1) {
          date.setTime(time - (7 - _day + 1) * 24 * 3600000);
          let end = moment(date).format("yyyy-MM-DD");
          return end;
        } else if (weeks == 54) {
          //第54周特殊处理
          let start = time + (weeks - 2) * 7 * 24 * 3600000;
          start = start + (_day - 1) * 24 * 3600000;
          date.setTime(start);
          let _date = moment(date).format("yyyy-MM-DD");
          return _date;
        } else {
          let start = time + (weeks - 2) * 7 * 24 * 3600000; //第n周开始时间
          start = start + (_day - 1) * 24 * 3600000;
          date.setTime(start);
          let _date = moment(date).format("yyyy-MM-DD");
          return _date;
        }
      }
    };

    // 执行语音命令
    const executeVoiceCommand = (nlp: any, result: any, user: any) => {
      console.log("用户信息： " + JSON.stringify(user));
      let chatModel = {
        isWaited: true,
        question: "",
        answer: "",
      };
      let DomainClassinfo = "task";
      if (result.DomainClassinfo) {
        DomainClassinfo = result.DomainClassinfo.toLowerCase();
      }
      switch (DomainClassinfo) {
        case "task":
          if (result.intentName.indexOf("Query") == 0) {
            // 查询任务
            let searchPanelForm = {
              limit: 100,
              offset: 1,
              name: "", //任务名称
              priority: "", //优先级（多选）不传默认全部
              // overdueStatus: "", //状态（单选）不传默认全部，1未逾期，2已逾期，3逾期完成
              // isFinish: "", //是否完成 不传默认全部，0未完成，1已完成
              workUsers: "", //执行人
              joinUsers: "", // 参与人
              creatorId: "", // 创建人
              startTime: "",
              endTime: "",
              createTimeStart: "",
              createTimeEnd: "",
              finishTimeStart: "",
              finishTimeEnd: "",
              queryType: "task",
            };
            let dateStr = "";
            let date;
            if (result.date) {
              dateStr = result.date;
              // 处理逻辑 时间格式
            } else if (result["createTime"]) {
              dateStr = result.createTime;
            }
            if (dateStr) {
              date = formatDatetime(dateStr, result.suggestDatetime);
              if (!date) {
                pushChatArr(true, "", "我无法理解您说的时间，请换个说法吧");
                return;
              }
            }
            if (
              result.intentName == "Query_Task_Info" ||
              result.intentName == "Query_joinTask"
            ) {
              if (dateStr && date) {
                if (result.taskIsFinished == "1") {
                  searchPanelForm.finishTimeStart = date[0];
                  searchPanelForm.finishTimeEnd = date[1];
                } else {
                  if (result.intentName != "Query_ExpireTask")
                    searchPanelForm.startTime = date[0];
                  searchPanelForm.endTime = date[1];
                }
              }
              if (result.intentName == "Query_joinTask")
                searchPanelForm.joinUsers = user.id;
              else searchPanelForm.workUsers = user.id;
            } else if (result.intentName == "Query_createTask") {
              if (dateStr && date) {
                if (result["createTime"]) {
                  searchPanelForm.createTimeStart = date[0];
                  searchPanelForm.createTimeEnd = date[1];
                } else {
                  if (result.taskIsFinished == "1") {
                    searchPanelForm.finishTimeStart = date[0];
                    searchPanelForm.finishTimeEnd = date[1];
                  } else {
                    searchPanelForm.startTime = date[0];
                    searchPanelForm.endTime = date[1];
                  }
                }
              }
              searchPanelForm.creatorId = user.id;
            } else if (result.intentName == "Query_ExpireTask") {
              if (dateStr && date) {
                searchPanelForm.endTime = date[1];
              }
            }

            // 任务查询条件公共参数赋值  需要的直接从这里写  start******

            if (result.priority) searchPanelForm.priority = result.priority;
            if (result.taskIsFinished) {
              searchPanelForm = Object.assign(searchPanelForm, {
                isFinish: Number.parseInt(result.taskIsFinished),
              });
            }
            if (result.overdueStatus) {
              searchPanelForm = Object.assign(searchPanelForm, {
                overdueStatus: Number.parseInt(result.overdueStatus),
              });
            }
            // if (result.overdueStatus)
            //   searchPanelForm.overdueStatus = result.overdueStatus;
            if (result.taskName) searchPanelForm.name = result.taskName;
            // 任务查询条件公共参数赋值   end******
            // 处理消息数组
            if (chatModel.question && chatModel.answer) {
              chatArr.data.splice(chatArr.data.length - 1, 1, chatModel);
              //最新消息滚动到顶部
              scrollToTop();
            }
            islist.value = true;
            taskList.data = [];

            console.log(
              "searchPanelForm 查询参数" + JSON.stringify(searchPanelForm)
            );
            taskService
              .getTaskList(searchPanelForm)
              .then((res: any) => {
                islist.value = false;
                let answer = "";
                if (res.length > 0) {
                  taskList.data = res;
                  answer = result.answerText.replace("{count}", res.length);
                } else {
                  answer = result.answerTextForNot;
                }
                pushChatArr(true, "", answer);
                title.value = "查询结果";
              })
              .catch(() => {
                islist.value = false;
                pushChatArr(true, "", "查询任务信息错误");
              });
          } else if (result.intentName == "createForm_taskandSCH") {
            // 新建任务
            // 创建表单业务，并且予以理解结束、槽位填满
            let task = buildTask(result, user);
            if (!task) {
              pushChatArr(true, "", "创建失败");
              return;
            }
            console.log("任务信息： " + JSON.stringify(task));
            taskService.insertTask(task).then((res: any) => {
              if (res) {
                pushChatArr(true, "", nlp.intent.answer.text);
                //最新消息滚动到顶部
                scrollToTop();
                taskList.data = [task];
                title.value = "新建结果";
                islist.value = false;
              } else {
                pushChatArr(true, "", "创建失败");
                islist.value = false;
              }
            });
          }
          break;
        case "schedule":
          //跳转路由
          // Utils.presentToastSuccess("跳转日程详情");
          if (result.intentName == "Query_Schedule") {
            let params = {
              limit: 100,
              offset: 1,
              schduleDate: "",
              scheduleName: "",
              calendarId: "",
              sort: "",
              searchKey: "",
              startTimeStart: "",
              endTimeEnd: "",
              userIds: "",
              priority: "",
              feedbackValue: "",
              queryType: "schedule",
            };
            if (result.feedbackValue)
              params.feedbackValue = result.feedbackValue;
            if (result.priority) params.priority = result.priority;
            if (result.date) {
              let date = formatDatetime(
                result.date,
                result.suggestDatetime,
                true
              );
              if (typeof date === "string") {
                params.schduleDate = date;
              } else {
                if (date) {
                  params.startTimeStart = date[0];
                  params.endTimeEnd = date[1];
                } else {
                  pushChatArr(true, "", "我无法理解您说的时间，请换个说法吧");
                  return;
                }
              }
            }
            if (user.id == SystemUtils.loginUser.id) {
              result.UserName = "my";
            } else {
              params.userIds = user.id;
            }
            console.log("params: " + JSON.stringify(params));
            if (result.UserName.toLowerCase() == "my") {
              // 查我的日程
              // 处理消息数组
              if (chatModel.question && chatModel.answer) {
                chatArr.data.splice(chatArr.data.length - 1, 1, chatModel);
                //最新消息滚动到顶部
                scrollToTop();
              }
              scheduleService
                .getMyScheduleList(params)
                .then(
                  (res: any) => {
                    console.log("data: " + JSON.stringify(res.data));
                    if (!res || !res.data) {
                      return;
                    }
                    res.data.forEach((element: any) => {
                      if (element.typeValue + "" == "") {
                        element.isSure = false;
                      } else if (element.typeValue + "" == "0") {
                        if (element.creatorId == SystemUtils.loginUser.id) {
                          element.isSure = false;
                        } else {
                          element.isSure = true;
                        }
                      } else {
                        element.isSure = false;
                      }
                    });
                    islist.value = false;
                    scheduleList.data = res.data;
                    if (scheduleList.data.length > 0) {
                      pushChatArr(
                        true,
                        "",
                        result.answerText.replace("{count}", res.data.length)
                      );
                    } else {
                      pushChatArr(true, "", result.answerTextForNot);
                    }
                    title.value = "查询结果";
                  },
                  (err: any) => {
                    islist.value = false;
                    pushChatArr(true, "", "查询日程信息错误");
                  }
                )
                .catch((err: any) => {
                  islist.value = false;
                  pushChatArr(true, "", "查询日程信息错误");
                });
            } else {
              // 查询他人日程
              OtherscheduleService.getOtherScheduleList(params)
                .then((res: any) => {
                  console.log("查询到日程： " + JSON.stringify(res.data));
                  if (res && res.data) {
                    islist.value = false;
                    scheduleList.data = res.data;
                    if (scheduleList.data.length > 0) {
                      pushChatArr(
                        true,
                        "",
                        result.answerText.replace("{count}", res.data.length)
                      );
                    } else {
                      pushChatArr(true, "", result.answerTextForNot);
                    }
                    title.value = "查询结果";
                  }
                })
                .catch((err: any) => {
                  pushChatArr(true, "", "查询日程信息错误");
                  islist.value = false;
                });
              // if(chatArr && chatArr.data && chatArr.data.length > 0)
              // chatArr.data.splice(chatArr.data.length - 1, 1, chatModel);
              // textToSpeech(chatModel.answer);
              //最新消息滚动到顶部
              setTimeout(() => {
                scrollToTop();
              }, 1000);
            }
          } else if (result.intentName == "createForm_taskandSCH") {
            // 新建日程
            // 创建表单业务，并且予以理解结束、槽位填满
            let schedule = buildSchedule(result, user);
            if (schedule != null) {
              console.log("Create Schedule： " + JSON.stringify(schedule));
              scheduleService
                .insertSchedule(schedule)
                .then(
                  (res: any) => {
                    if (res.data.isSuccess) {
                      pushChatArr(true, "", nlp.intent.answer.text);
                      //最新消息滚动到顶部
                      scrollToTop();
                      if (schedule != null)
                        schedule.scheduleTimeId = res.data.scheduleTimeId;
                      scheduleList.data = [schedule];
                      title.value = "新建结果";
                      saveCalendarEvent(schedule);
                    } else {
                      pushChatArr(true, "", "创建失败");
                    }
                  },
                  (err: any) => {
                    console.log("err1: " + JSON.stringify(err));
                  }
                )
                .catch((err: any) => {
                  console.log("err2: " + JSON.stringify(err));
                });
            }
          }
          break;
        case "project":
          if (result.intentName != "Query_Project") {
            return;
          }
          let parmas = {
            isDeleted: 0,
            userId: "",
            projectName: "",
            createTimeCB: "",
            createTimeCE: "",
            endTimeCB: "",
            endTimeEE: "",
            projectLight: "",
            createTimeOrder: "DESC",
            statusLightOrder: "DESC",
            orderType: "createTime",
            systemId: "",
            createIds: "",
            partakeType: "",
            searchKey: "",
            offset: 1,
            limit: 100,
          };
          if (result.date) {
            let date = formatDatetime(result.date, result.suggestDatetime);
            if (date) {
              parmas.createTimeCB = date[0];
              parmas.endTimeEE = date[1];
            } else {
              pushChatArr(true, "", "我无法理解您说的时间，请换个说法吧");
              return;
            }
          }
          if (result.partakeType) parmas.partakeType = result.partakeType;
          if (result.name) parmas.projectName = result.name;
          parmas.userId = SystemUtils.loginUser.id;
          if (result.UserName != "My" && user.id != parmas.userId) {
            if (
              result.partakeType == "myStar" ||
              result.partakeType == "myPart"
            ) {
              console.log("hold no brief for.............");
              pushChatArr(true, "", "暂不支持查询他人参与或收藏的项目");
              return;
            } else if (result.partakeType == "myCreate") {
              parmas.createIds = user.id;
            }
          }
          console.log("select project params: " + JSON.stringify(parmas));
          projectService.getProjectList(parmas).then(
            (r: any) => {
              if (r && r.length > 0) {
                projectLists.data = r.data;
                islist.value = false;
                title.value = "查询结果";
                pushChatArr(
                  true,
                  "",
                  result.answerText.replace("{count}", r.data.length)
                );
              } else {
                pushChatArr(true, "", result.answerTextForNot);
                islist.value = false;
              }
            },
            (e: any) => {
              islist.value = false;
              pushChatArr(true, "", "查询失败");
            }
          );
          break;
        case "okr":
          if (result.intentName != "Query_OKR") {
            return;
          }
          // "DomainClassinfo":"OKR",
          // "UserName":"My",
          // "answerText":"已查到{count}个OKR",
          // "dataLevel":null,
          // "date":"Y2021M04D19",
          // "departmentName":null,
          // "dimension":null,
          // "feedbackValue":null,
          // "intentName":"Query_OKR",
          // "intentType":"query",
          // "name":null,
          // "partakeType":null,
          // "priority":null,
          // "speak":"end",
          // "status":null,
          // "suggestDatetime":"2021-04-19"
          let params = {
            limit: 100,
            offset: 1,
            dutyType: "2",
            orgIds: [],
            levelId: "",
            levelName: "", // 级别名称
            cycleId: "",
            cycleName: "", // 周期名称
            categoryId: "",
            categoryName: "", // 维度名称
            name: "",
            dutyUsers: new Array<string>(0),
            creatorIds: [],
            createTimeStart: "",
            createTimeEnd: "",
            endTimeStart: "",
            endTimeEnd: "",
            status: "",
          };
          // if (result.suggestDatetime && result.date) {
          //   let date = formatDatetime(result.date, result.suggestDatetime);
          //   if (date) {
          //     params.createTimeStart = date[0];
          //     params.createTimeEnd = date[0];
          //     params.endTimeStart = date[1];
          //     params.endTimeEnd = date[1];
          //   } else {
          //     pushChatArr(true, "", "我无法理解您说的时间，请换个说法吧");
          //     return;
          //   }
          // }
          if (result.OKRPeriod) params.cycleName = result.OKRPeriod;
          if (result.status) params.status = result.status;
          if (result.dataLevel) params.levelName = result.dataLevel;
          params.dutyUsers.push(user.id as string);
          console.log("OKR查询参数： " + JSON.stringify(params));
          objectiveService
            .objectiveList(params)
            .then((res: any) => {
              console.log("OKR查询成功： " + JSON.stringify(res));
              islist.value = false;
              if (res && res.length > 0) {
                targetList.data = res;
                pushChatArr(
                  true,
                  "",
                  result.answerText.replace("{count}", res.length)
                );
                title.value = "查询结果";
              } else {
                pushChatArr(true, "", result.answerTextForNot);
              }
            })
            .catch((e: any) => {
              console.log("OKR查询失败： " + JSON.stringify(e));
              pushChatArr(true, "", "OKR查询失败");
              islist.value = false;
            });
          break;
        default:
          break;
      }
    };

    // 解析语音命令
    const analysisAndOperation = (repContent: Array<any>): void => {
      if (!repContent) {
        return;
      }
      // let iat = repContent.value.find((item) => item.sub == "iat"); // 识别内容
      let nlp = repContent.find((item) => item.sub == "nlp"); //语义返回内容
      let chatModel = {
        isWaited: true,
        question: "",
        answer: "",
        userList: new Array<any>(),
        nlp: "",
        result: "",
      };
      if (!nlp || !nlp.intent || JSON.stringify(nlp.intent) == "{}") {
        pushChatArr(true, "", "未听清您说的话，请重试！", "none");
        textToSpeech("未听清您说的话，请重试");
        return;
      }
      chatModel.question = nlp.intent.text;
      chatModel.answer = nlp.intent.answer.text;
      chatArr.data.splice(chatArr.data.length - 1, 1, chatModel);
      nextTick(() => {
        scrollToTop();
      });
      if (
        (nlp.intent.answer["answerType"] != undefined &&
          nlp.intent.answer.answerType == "LastGuard") ||
        !nlp.intent.data.result ||
        nlp.intent.data.result[0].speak == "ask"
      ) {
        // 不能识别，语音未击中技能, 或者无数据返回，或者必填槽位未满足
        // pushChatArr(true, "", nlp.intent.answer.text);
        textToSpeech(nlp.intent.answer.text);
        return;
      }
      //处理返回值 拼接查询参数
      if (!nlp.intent.data) {
        console.log("nlp.intent.data 为空");
        // 处理消息数组
        if (chatModel.question && chatModel.answer) {
          // chatArr.data.splice(chatArr.data.length - 1, 1, chatModel);
          textToSpeech(chatModel.answer);
          //最新消息滚动到顶部
          scrollToTop();
        }
        return;
      }
      let result = nlp.intent.data.result;
      if (!result || result.length <= 0) {
        console.log("result 云处理拼接的data 为空");
        // 处理消息数组
        if (chatModel.question && chatModel.answer) {
          // chatArr.data.splice(chatArr.data.length - 1, 1, chatModel);
          textToSpeech(chatModel.answer);
          //最新消息滚动到顶部
          scrollToTop();
        }
        return;
      }
      result = result[0];
      if (result.speak != "end") {
        // 未结束对话
        // 处理消息数组
        if (chatModel.question && chatModel.answer) {
          chatArr.data.splice(chatArr.data.length - 1, 1, chatModel);
          textToSpeech(chatModel.answer);
          //最新消息滚动到顶部
          scrollToTop();
        }
        return;
      }
      let intentTypes = [
        "OpenPage",
        "closevoice",
        "openvoice",
        "CloseVoicePage",
      ];
      let uName = "";
      if (result.UserName) {
        uName = result.UserName.toLowerCase();
      } else {
        if (result["joinorName"]) {
          uName = result.joinorName.toLowerCase();
        } else if (result["creatorName"]) {
          uName = result.creatorName.toLowerCase();
        }
      }
      if (intentTypes.indexOf(result.intentType) < 0 && !uName) {
        chatModel.answer = "缺少目标用户信息";
        chatArr.data.splice(chatArr.data.length - 1, 1, chatModel);
        textToSpeech(chatModel.answer);
        return;
      }
      if (result.intentType == "OpenPage") {
        switch (result.DomainClassinfo) {
          case "Visitor": // 访客
            router.push("/visitor");
            break;
          case "Meeting": // 会议
            router.push("/meeting");
            break;
          case "Attendance": // 考勤
            router.push("/attendance");
            break;
          case "Project": // 项目
            router.push("/project");
            break;
          case "Schedule": // 日程
            router.push("/calendar");
            break;
          case "OKR": // OKR
            router.push("/OKR");
            break;
          case "Task": // 任务
            router.push("/task-list");
            break;
          case "Homepage":
            router.push("/");
            break;
          default:
            break;
        }
        chatModel.answer = result.answerText;
        chatArr.data.splice(chatArr.data.length - 1, 1, chatModel);
        textToSpeech(chatModel.answer);
        return;
      } else if (result.intentType == "closevoice") {
        changePlay(false);
        chatModel.answer = result.answerText;
        chatArr.data.splice(chatArr.data.length - 1, 1, chatModel);
        textToSpeech(chatModel.answer);
        return;
      } else if (result.intentType == "openvoice") {
        changePlay(true);
        chatModel.answer = result.answerText;
        chatArr.data.splice(chatArr.data.length - 1, 1, chatModel);
        textToSpeech(chatModel.answer);
        return;
      } else if (result.intentType == "CloseVoicePage") {
        router.go(-1);
        return;
      }
      chatModel.question = nlp.intent.text;
      chatModel.answer = "";
      chatArr.data.splice(chatArr.data.length - 1, 1, chatModel);
      taskList.data = new Array<any>();
      scheduleList.data = new Array<any>();
      projectLists.data = new Array<any>();
      targetList.data = new Array<any>();
      if (uName != "my") {
        //验证人名是否重复 调用接口
        getUserInfoByPinyin(uName, (userInfos: any) => {
          console.log("验证是否有重名的人" + JSON.stringify(userInfos));
          if (userInfos.length > 1) {
            chatModel.answer = "有" + userInfos.length + "个相似的用户，请选择";
            //补充说明
            chatModel.userList = userInfos;
            chatModel.nlp = nlp;
            chatModel.result = result;
          } else if (userInfos.length == 1) {
            executeVoiceCommand(nlp, result, userInfos[0]);
          } else {
            taskList.data = [];
            chatModel.answer = "查询用户失败，未查询到此用户";
          }
          if (userInfos.length != 1) {
            chatArr.data.splice(chatArr.data.length - 1, 1, chatModel);
            textToSpeech(chatModel.answer);
            //最新消息滚动到顶部
            setTimeout(() => {
              scrollToTop();
            }, 1000);
          }
        });
      } else {
        executeVoiceCommand(nlp, result, SystemUtils.loginUser);
      }
    };

    const scrollToTop = () => {
      //最新消息滚动到顶部
      nextTick(() => {
        const view: any = document.querySelector("#messageList");
        if (view != null) {
          const ele: Element = view;
          ele.scrollTo({
            top: ele.scrollHeight,
          });
        }
      });
    };
    const checkSVW = (taskInfo: any) => {
      if (isSVW && taskInfo.projectId) {
        return false;
      } else return true;
    };
    // 跳转任务详情
    const openTaskInfo = (task: any, index: number) => {
      clickType = "task";
      clickIndex = index;
      router.push({
        path: "/task-page",
        query: {
          id: task.id,
          taskName: task.name,
        },
      });
    };
    // 跳转日程详情
    const detailHandler = (item: any, index: number) => {
      clickType = "schedule";
      clickIndex = index;
      router.push({
        path: "/schedule/detail/feedback",
        query: {
          id: item.id,
          scheduleTimeId: item.scheduleTimeId,
          showCalendar: "true",
        },
      });
    };
    // 跳转项目详情
    const projectItemClick = (project: any, index: number) => {
      clickType = "project";
      clickIndex = index;
      router.push({
        path: "/ProjectPage",
        query: {
          projectId: project.id,
          projectName: project.name,
          isStart: project.isStart,
          chatGroupId: project.chatGroupId,
          folderId: project.folderId,
          isDeleted: project.isDeleted,
        },
      });
    };
    // 跳转OKR目标详情
    const openTargetInfo = (id: any, index: number) => {
      clickType = "target";
      clickIndex = index;
      router.push({
        path: "/OKR/details",
        query: {
          id: id,
        },
      });
    };
    // 获取okr详情
    const getTargetInfo = (id: string) => {
      objectiveService.objectiveDetails({ id: id }).then(
        (r: any) => {
          if (r) {
            targetList.data[clickIndex] = r;
          }
        },
        (e: any) => {
          console.log("err: " + JSON.stringify(e));
        }
      );
    };
    // 获取日程详情
    const getScheduleInfo = (id: string, timeId: string) => {
      let params = {
        scheduleId: id,
        scheduleTimeId: timeId,
      };
      scheduleService.getScheduleDetail(params).then((result: any) => {
        scheduleList.data[clickIndex] = result.data;
      });
    };
    // 获取任务详情
    const getTaskInfo = (id: string) => {
      taskService.getTaskInfo(id).then((res: any) => {
        taskList.data[clickIndex] = res.task;
      });
    };
    // 根据拼音查询人员信息 判断是否有重名的人员
    const getUserInfoByPinyin = (userName: any, callback: any) => {
      userService
        .getUserInfoByPinyin({ pinyin: userName })
        .then((res) => {
          callback(res);
        })
        .catch((ex) => {
          console.log(JSON.stringify(ex));
        });
    };
    onUnmounted(() => {
      if (animationInterval != null) {
        clearInterval(animationInterval);
      }
    });

    const clickItem = (item: any, user: any) => {
      islist.value = true;
      if (user) {
        selectUserId.value = user.id;
        executeVoiceCommand(item.nlp, item.result, user);
      }
    };
    // 创建提交的任务实体数据
    const buildTask = (result: any, OUser: any) => {
      // 创建表单
      let task = taskService.getNewModel({
        priority: "0",
        projectId: "",
        executorUsers: [],
        partUsers: [],
        pos: 0,
        planWorkingHour: "0", // 工时
      });
      task.name = result.taskName;
      let user = {
        userId: OUser.id,
        name: OUser.name,
        photo: OUser.photo,
        pernr: OUser.pernr,
        department: OUser.department,
      };
      if (result.date) {
        // 处理逻辑 时间格式
        let date = formatDatetime(result.date, result.suggestDatetime);
        if (date) {
          task.startTime = date[0];
          task.endTime = date[1];
        } else {
          pushChatArr(true, "", "我无法理解您说的时间，请换个说法吧");
          return null;
        }
      }
      if (result.planedworkinghour)
        task.planWorkingHour = result.planedworkinghour;
      if (result.priority) task.priority = result.priority;
      // if (result.suggestDatetime.indexOf("/") != -1) {
      //   task.startTime = result.suggestDatetime.split("/")[0];
      //   task.endTime = result.suggestDatetime.split("/")[1];
      // } else {
      //   task.startTime = result.suggestDatetime;
      //   task.endTime = result.suggestDatetime;
      // }
      if (task.partUsers && task.partUsers.length == 0) {
        //默认当前人就是参与人
        task.partUsers.push(
          Object.assign(SystemUtils.loginUser, {
            workType: EUserWorkType.Party,
            userId: SystemUtils.loginUser.id,
          })
        );
      }
      if (task.executorUsers && task.executorUsers.length == 0) {
        // 添加执行人
        task.executorUsers.push(
          Object.assign(user, { workType: EUserWorkType.Executor })
        );
      }
      if (task.executorUsers || task.partUsers) {
        const executorUsers = parseUserFormat(
          EUserWorkType.Executor,
          task.executorUsers,
          task
        ); //执行人类型
        const partUsers = parseUserFormat(
          EUserWorkType.Party,
          task.partUsers,
          task
        ); //参与人类型
        task.taskUser = executorUsers.concat(partUsers);
        task = JSON.parse(JSON.stringify(task));
        delete task.executorUsers;
        delete task.partUsers;
      }
      return task;
    };

    // 创建提交的日程实体数据
    const buildSchedule = (result: any, OUser: any) => {
      let schedule = {
        id: Utils.getUuid,
        name: "",
        address: "",
        startTime: "",
        endTime: "",
        isAllday: false,
        remark: "",
        needFeedback: false,
        priority: "0",
        attachment: [],
        folderId: "",
        isRepeat: false,
        isFinish: false,
        isDeleted: false,
        updateType: "-1",
        chatGroupId: "",
        relationId: "",
        relationFirstId: "",
        scheduleParticipantList: new Array<any>(0),
        scheduleWarnList: new Array<any>(0),
        scheduleRepeatSetting: {},
        sheduleWarningRuleModel: {},
        scheduleTimeId: "",
      };
      schedule.priority = result.priority || "0";
      let date = formatDatetime(result.date, result.suggestDatetime, true);
      if (!date) {
        pushChatArr(true, "", "我无法理解您说的时间，请换个说法吧");
        return null;
      } else {
        if (typeof date === "string") {
          schedule.startTime = date;
          schedule.endTime = date;
        } else {
          schedule.startTime = date[0];
          schedule.endTime = date[1];
        }
      }
      if (schedule.startTime.length == 10 && schedule.endTime.length == 10) {
        schedule.isAllday = true;
      }
      if (schedule.startTime.length == 10)
        schedule.startTime = schedule.startTime + " 00:00:00";
      if (schedule.endTime.length == 10)
        schedule.endTime = schedule.endTime + " 23:59:59";
      schedule.name = result.taskName;

      let repeatParams = {
        type: "never",
        repeat: "",
        span: 0,
        dateList: [],
      };
      let endtime = moment(
        schedule.endTime == "" ? new Date() : schedule.endTime
      ).format("yyyy-MM-DD");
      let deadTimeStr = "";
      if (endtime.length == 10) {
        deadTimeStr =
          moment(endtime).endOf("day").format("yyyy-MM-DD HH:mm:ss") + "";
      } else {
        deadTimeStr =
          moment(endtime.substring(0, 10))
            .endOf("day")
            .format("yyyy-MM-DD HH:mm:ss") + "";
      }
      schedule.scheduleRepeatSetting = {
        id: Utils.getUuid,
        scheduleId: schedule.id,
        parameter: JSON.stringify(repeatParams),
        deadTime: deadTimeStr,
      };

      schedule.sheduleWarningRuleModel = {
        id: Utils.getUuid,
        scheduleId: schedule.id,
        typeValue: -1,
        description: "",
        unit: "",
        intervals: 0,
      };
      schedule.scheduleParticipantList.push({
        id: Utils.getUuid,
        scheduleId: schedule.id,
        userId: OUser.id,
        userName: OUser.name,
      });
      schedule.scheduleWarnList.push({
        id: Utils.getUuid,
        wariningId: Utils.getUuid,
        userId: OUser.id,
        userName: OUser.name,
      });
      if (SystemUtils.loginUser.id && OUser.id != SystemUtils.loginUser.id) {
        schedule.scheduleParticipantList.push({
          id: Utils.getUuid,
          scheduleId: schedule.id,
          userId: SystemUtils.loginUser.id,
          userName: SystemUtils.loginUser.name,
        });
        schedule.scheduleWarnList.push({
          id: Utils.getUuid,
          wariningId: Utils.getUuid,
          userId: SystemUtils.loginUser.id,
          userName: SystemUtils.loginUser.name,
        });
      }
      return schedule;
    };

    //获取格式化后的用户
    const parseUserFormat = (type: number, list: Array<any>, task: any) => {
      const taskUsers = [];
      if (list) {
        for (let i = 0; i < list.length; i++) {
          taskUsers.push(
            taskUserApi.getNewUserModel({
              userId: list[i].userId,
              name: list[i].name,
              workType: type,
              taskId: task.id,
              projectId: task.projectId ? task.projectId : "",
            })
          );
        }
      }
      return taskUsers;
    };

    const playAudo = (filePath: any) => {
      console.log("预览文件" + filePath);
    };
    return {
      title,
      playAudo,
      currentUserName,
      checkSVW,
      openTaskInfo,
      detailHandler,
      projectItemClick,
      openTargetInfo,
      taskList,
      scheduleList,
      projectLists,
      targetList,
      chatArr,
      searchQuestion,
      voiceText,
      touchstart,
      touchend,
      touchmove,
      repContent,
      startCapture,
      stopCapture,
      analysisAndOperation,
      voiceStatus,
      animationIndex,
      islist,
      clickItem,
      speakDisabled,
      selectUserId,
      flagCount,
      btnIconRight,
      changeIon,
      changePlay,
      searchQuestionClick,
    };
  },
});
